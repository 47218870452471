import { httpClient } from "./http-client-helper";

export const getShippingRate = async (address) => {
    try {        
        const result = await httpClient().get(`/shipping-rate?address=${encodeURI(address)}`);        
        return result.data;
    } catch (error) {
        console.error("Unable to get shipping rate.")
    }
}
